import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('upload:setup', '.js-upload-marketplace-listing-screenshot', function (event) {
  const {form} = event.detail
  const listingID = event.currentTarget.getAttribute('data-marketplace-listing-id')
  if (listingID) {
    form.append('marketplace_listing_id', listingID)
  }
})

on('upload:complete', '.js-upload-marketplace-listing-screenshot', async function (event) {
  const screenshotsUrl = event.currentTarget.getAttribute('data-screenshots-url')!
  const container = document.querySelector<HTMLElement>('.js-marketplace-listing-screenshots-container')!
  const html = await fetchSafeDocumentFragment(document, screenshotsUrl)
  container.textContent = ''
  container.appendChild(html)
})
