// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onInput} from '@github-ui/onfocus'

on('click', '.js-listing-delist-button', function ({currentTarget}) {
  const dialogId = currentTarget.getAttribute('data-show-dialog-id')!
  const modal = document.getElementById(dialogId) as HTMLElement

  modal.addEventListener(
    'cancel',
    () => {
      resetDelistModal()
    },
    {once: true},
  )
  modal.addEventListener(
    'close',
    () => {
      resetDelistModal()
    },
    {once: true},
  )
})

on('click', '.js-listing-delist-proceed-button', function ({currentTarget}) {
  const button = currentTarget as HTMLButtonElement
  const nextStage = button.getAttribute('data-next-stage')!

  if (nextStage) {
    transitionDeleteModalTo(nextStage)
  }
})

onInput('.js-listing-delist-proceed-confirmation', function ({currentTarget}) {
  const input = currentTarget as HTMLInputElement
  const repoNwo = input.getAttribute('data-repo-nwo')!

  if (repoNwo) {
    const button = document.getElementById(`listing-delist-proceed-button`) as HTMLButtonElement
    const proceed = input.value === repoNwo

    if (button && proceed) {
      const form = input.closest('form') as HTMLFormElement

      if (form) {
        addHiddenFormInput(form, 'verify', repoNwo)
        button.disabled = false
      }
    } else {
      button.disabled = true
    }
  }
})

function addHiddenFormInput(form: HTMLFormElement, name: string, value: string) {
  const newInput = document.createElement('input')
  newInput.setAttribute('type', 'hidden')
  newInput.setAttribute('name', name)
  newInput.setAttribute('value', value)

  form.appendChild(newInput)
}

function resetDelistModal() {
  transitionDeleteModalTo('1')
}

function transitionDeleteModalTo(stage: string) {
  const replacementWarningTemplate = document.getElementById(`delist-listing-step-${stage}`) as HTMLTemplateElement
  const replacementButtonTemplate = document.getElementById(
    `proceed-button-delist-step-${stage}`,
  ) as HTMLTemplateElement

  if (replacementWarningTemplate && replacementButtonTemplate) {
    const replacementButtonTemplateClone = replacementButtonTemplate.content.cloneNode(true)
    const replacementWarningTemplateClone = replacementWarningTemplate.content.cloneNode(true)

    const warningContainer = document.getElementById(`listing-delist-warning-container`) as HTMLDivElement
    const existingButtonContainer = document.getElementById(`listing-delist-proceed-button-container`) as HTMLDivElement

    if (warningContainer && existingButtonContainer) {
      warningContainer.textContent = ''
      existingButtonContainer.textContent = ''
      warningContainer.appendChild(replacementWarningTemplateClone)
      existingButtonContainer.appendChild(replacementButtonTemplateClone)
    }
  }
}
